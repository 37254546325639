import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";

// pages
import Index from './pages/index';
import Page from './pages/page';

import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyAPkK9kG6NOk1P_cSGhOtbxdw8dO4u1qws",
  authDomain: "umkocanoemarathon.firebaseapp.com",
  projectId: "umkocanoemarathon",
  storageBucket: "umkocanoemarathon.appspot.com",
  messagingSenderId: "515399377877",
  appId: "1:515399377877:web:ccd1b6ed1c16aabe9fd35c",
  measurementId: "G-2REF9XCVLL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeAnalytics(app);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Index />,
    errorElement: <Index />,
  },
  {
    path: '/umko/*',
    element: <Page />,
  }
  // {
  //   path: '/entries',
  //   element: <Entry />
  // },

  // {
  //   path: '/events',
  //   element: <Event />
  // },
  // {
  //   path: '/'
  // }
  // {
  //   path: '/contact',
  //   element: <Contact />
  // },
  // {
  //   path: '/news/*',
  //   element: <News />
  // },
  // {
  //   path: '/news',
  //   element: <News />
  // },
  // {
  //   path: '/results',
  //   element: <Results />
  // }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
